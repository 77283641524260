import { createContext, useState } from 'react';
import { useRequest } from 'ahooks';
import { ShowQrank } from 'request/apis/portageurTypes';
import portageurApi from 'request/apis/portageur';

const initialQrank = () => ({
  data: {
    show_detail: 0,
    companies: [] as string[],
  } as unknown as ShowQrank,
  loading: false,
});

export const ChatContext = createContext({
  qrank: initialQrank(),
  isInvestment: false,
  conversationId: '',
  disabledShowSource: false,
  source: [] as number[],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setSource: (v: number[]) => void 0,
});

interface UseContextProps {
  qrank: {
    index: number;
    show: boolean;
    companies: string[];
  };
  qrankData: {
    data: ShowQrank;
    loading: boolean;
  };
  runQrank: (companies: string[], index) => void;
  initialQrank: () => void;
}

const UseContext = (insertQrank?: ShowQrank): UseContextProps => {
  const [ data, setData ] = useState(insertQrank || initialQrank().data);

  const [ qrank, setQrank ] = useState({
    index: insertQrank ? 1 : 0,
    show: !!insertQrank,
    companies: insertQrank ? [ 'apple' ] : ([] as string[]),
  });

  const { loading, run } = useRequest(
    companies =>
      portageurApi
        .searchQrank({
          companies: companies.join(','),
        })
        .then(res => res.data),
    {
      onSuccess: res => setData(res),
      manual: true,
    },
  );

  return {
    qrank,
    qrankData: {
      data,
      loading,
    },
    runQrank: (companies: string[], index) => {
      setQrank({ show: true, companies, index });
      run(companies);
    },
    initialQrank: () => {
      setQrank({ show: false, companies: [], index: 0 });
    },
  };
};

export default UseContext;
