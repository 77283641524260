import { Message, OnReference } from './types';
import styles from './index.module.css';
import ChatRequestStatus from 'components/ChatRequestStatus';
import { UserMessage } from './UserMessage';
import { BotMessage } from './BotMessage';
import classNames from 'classnames';
import { ErrorMessage } from './ErrorMessage';
import React, { useRef, Fragment, useEffect } from 'react';
import { useAsync } from 'hooks/useAsync';
import { FeedbackOptions } from 'request/apis/portageurTypes';
import { PortageurApi } from 'request/apis/portageur';
import Qrank from './Qrank';
import UsageInfo from './UsageInfo';

export type MessagesRendererProps = {
  qrank: {
    index: number;
    show: boolean;
    companies: string[];
  };
  retryCount: number;
  messages: Message[];
  onFeedback?: (args: { feedbackId: number; requestId: number }) => void;
  onReference?: OnReference;
  onResend?: () => void;
  bottomRef: any;
  autoScrollBottom: (v?: boolean) => void;
  isInvestment?: boolean;
};

const portageurApi = new PortageurApi();

async function fetchOptions() {
  const res = await portageurApi.getFeedbackOptions();

  return res.data;
}
const MessagesRenderer = React.forwardRef<
HTMLDivElement,
MessagesRendererProps
>(
  (
    {
      qrank,
      retryCount,
      messages,
      onFeedback,
      onReference,
      onResend,
      bottomRef,
      autoScrollBottom,
      isInvestment,
    },
    ref,
  ) => {
    const feedbackOptionsQuery = useAsync<FeedbackOptions>();
    const initialMessagesLength = useRef(messages.length);

    useEffect(() => {
      feedbackOptionsQuery.runAsync(fetchOptions());
    }, []);

    return (
      <div className={styles.messageContainer} ref={ref}>
        {messages.map((message, index) => {
          const addAnimation =
            initialMessagesLength.current !== messages.length &&
            index + 1 > initialMessagesLength.current;
          return (
            <Fragment key={index}>
              {qrank?.show && !!qrank?.companies?.length && qrank?.index === index && !isInvestment && (
                <div className={styles.messageWrapper}>
                  <Qrank
                    companies={qrank.companies}
                    className={styles.messageAnimation}
                  />
                </div>
              )}
              {message.status === 'progress' && isInvestment && (
                <div className={styles.processing}>
                Librarian is processing your request. This will take a few
                minutes. Enjoy a cup of coffee or tea while you wait. Or, ask
                another question in a new chat tab.
                </div>
              )}
              <div className={styles.messageWrapper}>
                {message.status === 'progress' && (
                  <ChatRequestStatus
                    message={message.message}
                    status={message.status}
                    className={classNames(styles.chatStatus)}
                  />
                )}

                {/error|retry|limit/.test(message.status) && (
                  <ErrorMessage
                    className={classNames(
                      addAnimation && styles.messageAnimation,
                      styles.error,
                    )}
                    onResend={onResend}
                    retryCount={retryCount}
                    isLastItem={index === messages.length - 1}
                    {...message}
                  />
                )}
                {/typeWriter|answer|question/.test(message.status) &&
                  (message.sender === 'user' ? (
                    <UserMessage {...message} />
                  ) : (
                    <BotMessage
                      feedbackOptions={feedbackOptionsQuery.data}
                      className={addAnimation && styles.messageAnimation}
                      onFeedback={onFeedback}
                      onReference={onReference}
                      disableAnimation={!addAnimation}
                      autoScrollBottom={autoScrollBottom}
                      {...message}
                    />
                  ))}
              </div>
              {isInvestment && message.sender === 'user' && index === 1 && (
                <div className={styles.messageWrapper}>
                  <Qrank
                    companies={qrank.companies}
                    className={styles.messageAnimation}
                  />
                </div>
              )}
              {message.status === 'answer' &&
                message.sender !== 'user' &&
                message.notification && (
                <div className={styles.messageWrapper}>
                  <UsageInfo className={styles.messageAnimation} />
                </div>
              )}
            </Fragment>
          );
        })}
        <div ref={bottomRef} />
      </div>
    );
  },
);

export default MessagesRenderer;
