import { ChatService } from 'request/apis/portageurTypes';
import styles from './ChatHistoryItem.module.css';
import { useState } from 'react';
import { Spin, Typography, Modal } from 'antd';
import { formatDate } from 'utils';
import { useNavigate } from 'react-router-dom';
import IconFont from 'components/IconFont';
import gTag from 'utils/gTag';
import { theme } from '../../theme';

export type ChatHistoryItemProps = {
  sessionId: string;
  date: Date;
  title: string;
  description: string;
  service: ChatService;
  onRemove: (args: { sessionId: string }) => void;
  onPin: (args: { sessionId: string; pin: boolean }) => void;
  isPinned: boolean;
  isMutating?: boolean;
  page?: number;
  types: number;
};

export const ChatHistoryItem = ({
  date,
  title,
  description,
  onPin,
  onRemove,
  sessionId,
  isMutating,
  isPinned,
  page,
  types,
  service,
}: ChatHistoryItemProps) => {
  const navigate = useNavigate();
  const [ isModalOpen, setIsModalOpen ] = useState(false);

  return (
    <>
      <Modal
        title=" "
        wrapClassName={styles.deleteModal}
        open={isModalOpen}
        okText="Delete"
        onOk={() => {
          onRemove({ sessionId });
          setIsModalOpen(false);
        }}
        onCancel={() => setIsModalOpen(!isModalOpen)}
      >
        <IconFont
          type="bin"
          style={{ fontSize: 55, color: theme.colors.orange }}
        />
        <p className={styles.deleteTitle}>Delete chat record?</p>
        <p className={styles.deleteText}>This action is irreversible.</p>
      </Modal>

      <div
        className={styles.container}
        onClick={() => {
          gTag.viewHistory();
          navigate(
            `/chat-history/${sessionId}?page=${page}&types=${types}&service=${service}`,
          );
        }}
      >
        <IconFont
          type="librarian"
          style={{
            fontSize: 32,
            color: theme.colors.orange,
            objectPosition: 'center',
          }}
        />
        <div className={styles.textWrapper}>
          <Typography.Title level={4}>{title}</Typography.Title>
          <div className={styles.dataWrapper}>
            <div className={styles.dot} />
            <Typography.Text style={{ fontSize: 12 }} type="secondary">
              {formatDate(date)}
            </Typography.Text>
            {isPinned && <IconFont type="pin" style={{ marginBottom: 5, fontSize: 12 }}/>}
          </div>
          <Typography.Text className={styles.description}>
            {description}
          </Typography.Text>
        </div>
        <div className={styles.iconWrapper}>
          {isMutating ? (
            <Spin />
          ) : (
            <>
              <IconFont
                type="pin-line"
                onClick={e => {
                  e.stopPropagation();
                  onPin({ sessionId, pin: !isPinned });
                }}
                style={{ fontSize: 17, marginTop: 1 }}
                className={styles.icon}
              / >
              <IconFont
                type="bin"
                onClick={e => {
                  e.stopPropagation();
                  setIsModalOpen(true);
                }}
                style={{ fontSize: 20 }}
                className={styles.icon}
              />
            </>
          )}
        </div>
        <IconFont
          type="right-circle"
          style={{ fontSize: 20 }}
          className={styles.rightIcon}
        />
      </div>
    </>
  );
};
