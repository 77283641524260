import axios from 'axios';
import {
  ShowQrank,
  BillingPortalUrl,
  Chat,
  ChatHistories,
  ChatHistoryDetail,
  Company,
  FeedbackOptions,
  Industry,
  Metric,
  PortageurResponse,
  InvestmentTemplate,
  PostFeedbackResponse,
  Reference,
  Sector,
  User,
  Table,
  SubscriptData,
} from './portageurTypes';
import { envVars } from 'utils/envVars';
import { interceptor, handerError } from '../interceptor';

const portageurApiInstance = axios.create({
  baseURL: envVars.portageurBaseUrl,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: 'Bearer ' + envVars.portageurApiKey,
  },
});

portageurApiInstance.interceptors.request.use(
  config => {
    const iamToken = localStorage.getItem('iamToken') || '';
    iamToken && (config.headers.Authorization = `Bearer ${iamToken}`);
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

portageurApiInstance.interceptors.response.use(interceptor, handerError);

export class PortageurApi {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor, @typescript-eslint/no-empty-function
  constructor() {}

  setToken(token: string) {
    axios.defaults.headers.common.Authorization = 'Bearer ' + token;
  }

  async searchCompanies({
    keyword = ' ',
    position,
    count,
    formatType,
  }: {
    keyword?: string;
    position?: number;
    count?: number;
    formatType?: 0 | 1;
  }) {
    const res = await portageurApiInstance.get<PortageurResponse<Company[]>>(
      '/search/company/v1/',
      {
        params: {
          keyword,
          pos: position,
          count,
          format_type: formatType,
        },
      },
    );

    return res.data;
  }

  async searchMetrics({ keyword }: { keyword?: string }) {
    const res = await portageurApiInstance.get<PortageurResponse<Metric[]>>(
      '/search/metric/v1/',
      {
        params: {
          keyword,
        },
      },
    );

    return res.data;
  }

  async searchIndustries({ keyword }: { keyword?: string }) {
    const res = await portageurApiInstance.get<PortageurResponse<Industry[]>>(
      '/search/industry/v1/',
      {
        params: {
          keyword,
        },
      },
    );

    return res.data;
  }

  async searchSectors({ keyword }: { keyword?: string }) {
    const res = await portageurApiInstance.get<PortageurResponse<Sector[]>>(
      '/search/sector/v1/',
      {
        params: {
          keyword,
        },
      },
    );

    return res.data;
  }

  async askQuestion({
    prompt,
    sessionId,
    requestId,
    retry,
    conversationId,
    source,
  }: {
    prompt: string;
    sessionId?: string;
    requestId?: number;
    retry?: number;
    conversationId?: string;
    source?: number[];

  }) {
    const res = await portageurApiInstance.post<PortageurResponse<Chat>>(
      '/chat/question/v3',
      {
        prompt,
        session_id: sessionId,
        request_id: requestId,
        conversation_id: conversationId,
        retry,
        source,
      },
      {
        timeout: 15000,
      },
    );

    return res.data;
  }

  async askInvestmentQuestion({
    prompt,
    sessionId,
    requestId,
    retry,
    conversationId,
    tid,
  }: {
    prompt: string;
    sessionId?: string;
    requestId?: number;
    retry?: number;
    conversationId?: string;
    tid?: string;
  }) {
    const res = await portageurApiInstance.post<PortageurResponse<Chat>>(
      '/investment/question/v1',
      {
        prompt,
        session_id: sessionId,
        request_id: requestId,
        conversation_id: conversationId,
        retry,
        tid,
      },
      {
        timeout: 15000,
      },
    );

    return res.data;
  }

  async getQuestion({
    sessionId,
    requestId = 0,
    conversationId,
  }: {
    sessionId?: string;
    requestId?: number;
    conversationId?: string;
  }) {
    const res = await portageurApiInstance.get<PortageurResponse<Chat>>(
      '/chat/question/v2',
      {
        params: {
          session_id: sessionId,
          request_id: requestId,
          conversation_id: conversationId,
        },
        timeout: 15000,
      },
    );

    return res.data;
  }

  async getInvestmentQuestion({
    sessionId,
    requestId = 0,
    conversationId,
  }: {
    sessionId?: string;
    requestId?: number;
    conversationId?: string;
  }) {
    const res = await portageurApiInstance.get<PortageurResponse<Chat>>(
      '/investment/question/v1',
      {
        params: {
          session_id: sessionId,
          request_id: requestId,
          conversation_id: conversationId,
        },
        timeout: 15000,
      },
    );

    return res.data;
  }

  async getFeedbackOptions() {
    const res = await portageurApiInstance.get<
    PortageurResponse<FeedbackOptions>
    >('/chat/feedback/v1');

    return res.data;
  }

  async sendFeedback({
    sessionId,
    requestId,
    feedbackId,
  }: {
    sessionId: string;
    requestId: number;
    feedbackId: number;
  }) {
    const res = await portageurApiInstance.post<PostFeedbackResponse>(
      '/chat/feedback/v1',
      {
        session_id: sessionId,
        request_id: requestId,
        feedback_id: feedbackId,
      },
    );

    return res.data;
  }

  async getReference({ referenceId }: { referenceId: string }) {
    const res = await portageurApiInstance.get<PortageurResponse<Reference>>(
      '/chat/reference/v1',
      {
        params: {
          reference_id: referenceId,
        },
      },
    );

    return res.data;
  }

  async getSuggestions({ caseId }: { caseId?: number }) {
    const res = await portageurApiInstance.get<PortageurResponse<string[]>>(
      '/chat/suggestion/v1',
      {
        params: {
          case_id: caseId,
        },
      },
    );

    return res.data;
  }

  async getInvestmentTemplates() {
    const res = await portageurApiInstance.get<PortageurResponse<InvestmentTemplate[]>>(
      '/investment/prompt_templates/v1',
    );

    return res.data;
  }

  async getChatHistory({
    current,
    pageSize,
  }: {
    current: number;
    pageSize: number;
  }) {
    const res = await portageurApiInstance.get<
    PortageurResponse<ChatHistories>
    >('/repository/chat/list/v3', {
      params: {
        page: current,
        limit: pageSize,
      },
    });

    return res.data;
  }

  async getChatHistoryDetail({ sessionId, types }: { sessionId: string, types: number }) {
    const res = await portageurApiInstance.get<
    PortageurResponse<ChatHistoryDetail>
    >('/repository/chat/detail/v3', {
      params: {
        session_id: sessionId,
        types,
      },
    });

    return res.data;
  }

  async removeChatHistory({ sessionId }: { sessionId: string }) {
    const res = await portageurApiInstance.post<PostFeedbackResponse>(
      '/repository/chat/delete/v2',
      {
        session_id: sessionId,
        delete: 1,
      },
    );

    return res.data;
  }

  async pinChatHistory({
    sessionId,
    pin,
  }: {
    sessionId: string;
    pin: boolean;
  }) {
    const res = await portageurApiInstance.post<PostFeedbackResponse>(
      '/repository/chat/pin/v2',
      {
        session_id: sessionId,
        pin: Number(pin),
      },
    );

    return res.data;
  }

  async getUser() {
    const res = await portageurApiInstance.get<PortageurResponse<User>>(
      '/user/user/v1',
    );

    return res.data;
  }

  async getBillingPortalUrl() {
    const res = await portageurApiInstance.get<
    PortageurResponse<BillingPortalUrl[]>
    >('/premium/subscription/billing_portal/v1');

    return res.data;
  }

  async searchQrank({ companies }) {
    const res = await portageurApiInstance.get<PortageurResponse<ShowQrank>>(
      'search/qrank/v2',
      {
        params: {
          companies,
        },
      },
    );

    return res.data;
  }

  async getPricingTable() {
    const res = await portageurApiInstance.get<PortageurResponse<Table>>(
      '/premium/subscription/price_table/v1',
    );

    return res.data;
  }

  async getSubscribe({ price_id }) {
    const res = await portageurApiInstance.get<
    PortageurResponse<SubscriptData>
    >('/premium/subscription/checkout/v1', {
      params: {
        price_id,
      },
    });
    return res.data;
  }
}

// 直接导出实例，在被引用时可以直接
// import portageurApi from 'request/apis/portageur'; 调用
export default new PortageurApi();
