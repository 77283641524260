import moment, { Moment } from 'moment';
import { ChatStatus } from 'request/apis/portageurTypes';
import { MessageSender } from 'components/MessagesRenderer/types';

export const format = 'YYYY-MM-DD HH:mm:ss';
export const simpleFormat = 'YYYY-MM-DD';
export const startFormat = 'YYYY-MM-DD 00:00:00';
export const endFormat = 'YYYY-MM-DD 23:59:59';
export const startDate = moment(
  moment().subtract(30, 'days').format(startFormat),
);
export const endDate = moment(moment().format(endFormat));

export const RANGE_FORMAT: { [key: string]: [Moment, Moment] } = {
  Today: [ moment(), moment() ],
  'Last 7 days': [ moment().subtract(6, 'days'), moment() ],
  'Last 30 days': [ moment().subtract(29, 'days'), moment() ],
  'Last 365 days': [ moment().subtract(364, 'days'), moment() ],
};

export const TABLE_INFO = {
  Basic: {
    intro: 'For Those Getting Started',
    feat: [
      '<strong><span style="color:#3A404D">40</span></strong> librarian requests per month',
      'Extensive document archive',
      'Direct Reference Links',
    ],
  },
  Premium: {
    intro: 'For Those Who Want to Go Deep',
    feat: [
      'Everything in Basic',
      '<strong><span style="color:#3A404D">400</span></strong> librarian requests per month',
      'Compare Companies',
      'Detailed Sustainability Scores',
      '<strong><span style="color:#3A404D">5x</span></strong> more documents analyzed',
    ],
  },
  Enterprise: {
    intro: 'For Fast-Moving Teams',
    feat: [ 'Everything in Premium', 'API Access' ],
  },
};

export const WELCOME_TIP = [
  {
    icon: 'icons',
    title: 'Ask 5 Questions Per Day',
    content: 'Get instant insights from corporate documents.',
  },
  {
    icon: 'highlighter',
    title: 'Audit with Document Highlights',
    content: 'Find data with confidence.',
  },
  {
    icon: 'review',
    title: 'Identify Policy Deficiencies & Benchmark',
    content:
      'Compare narratives and benchmark against peers to identify risks and opportunities.',
  },
];

export const FRONTEND_MESG = {
  CompanyCode: {
    message:
      "I don't understand your request. Please type '@' followed by company name.",
    status: 'error' as ChatStatus,
    sender: 'bot' as MessageSender,
  },
  Placeholder: {
    message: 'Please fill in or remove placeholders.',
    status: 'error' as ChatStatus,
    sender: 'bot' as MessageSender,
  },
  OneCompany: {
    message: "Enter one company and try again using '@companyname'.",
    status: 'error' as ChatStatus,
    sender: 'bot' as MessageSender,
  },
  AmericanCompany: {
    message:
      "This analysis is for U.S. listed companies. Try again using '@companyname'.",
    status: 'error' as ChatStatus,
    sender: 'bot' as MessageSender,
  },
  RecipeQuestion: {
    message:
      "Which company are you analyzing? Enter '@companyname'. This analysis is for U.S. public companies.",
    status: 'question' as ChatStatus,
    sender: 'bot' as MessageSender,
  },
  StartNew: {
    message: 'To ask more questions, start a new chat.',
    status: 'error' as ChatStatus,
    sender: 'bot' as MessageSender,
  },
};

export const SOURCE = [
  // { name: '10-K', description: 'US public companies only', code: 1001 },
  {
    name: '10-K/Annual Reports',
    description: 'US public companies',
    code: 2002,
  },
  {
    name: 'Investor Presentations',
    description: 'Growth strategies & future plans',
    code: 2003,
  },
  {
    name: 'Sustainability Reports',
    description: 'Impact on environmental, social & governance issues',
    code: 2001,
  },
  {
    name: 'Web Search',
    description:
      'Internet search (default selection for supplementary information)',
    code: 0,
    default: true,
  },
];
