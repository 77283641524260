import { SOURCE } from 'config/constant';
import React, { useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { ChatContext } from 'components/Chat/useContext';
import { Dropdown, Checkbox, Grid } from 'antd';
import IconFont from 'components/IconFont';
import ismobilejs from 'ismobilejs';
import styles from './index.module.css';

const SourceSelect = ({ width }) => {
  const { source, setSource, disabledShowSource } = useContext(ChatContext);
  const [ menuOpen, setMenuOpen ] = useState(false);
  const iconRef = useRef<HTMLSpanElement>(null);
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const isMobile = ismobilejs(window.navigator).any || !screens.lg;
  const hasSource = source.filter(v => v).length > 0;

  useEffect(() => {
    if (!hasSource) {
      setMenuOpen(true);
    }
  }, [ hasSource ]);

  useEffect(() => {
    iconRef.current?.style?.setProperty?.(
      'transform',
      `rotate(${menuOpen ? 180 : 0}deg)`,
    );
  }, [ menuOpen ]);


  const buttonStyle = {
    mobile: {
      backgroundColor: 'rgb(245,245,246)',
      justifyContent: 'space-between',
      borderRadius: '8px 8px 0 0',
      height: 38,
      border: '1px solid rgb(243,244,245)',
    },
    pc: {
      backgroundColor: 'transparent',
      justifyContent: 'center',
    },
  };

  const renderMenu = () => {
    return (
      <div
        className={styles.box}
        style={{ width: width ? width : '100%' }}
      >
        <div className={classNames(styles.note, !hasSource && styles.warn)}>
          <div className={styles.title}>Select at least one source. </div>
          <div className={styles.description}>
          Web Search is always on.
          </div>
        </div>
        <Checkbox.Group
          value={source}
          style={{ width: '100%' }}
          onChange={checkedValues => setSource(checkedValues as number[])}>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            {SOURCE.map((item, index) => (
              <div key={index} className={styles.checkItem}>
                <Checkbox value={item.code} disabled={item.default}>
                  <span className={styles.span}>
                    <span>{item.name}</span>
                    <span
                      style={{ color: '#B6B7BF', marginLeft: 8 }}
                      className={styles.desc}>
                      {item.description}
                    </span>
                  </span>
                </Checkbox>
              </div>
            ))}
          </div>
        </Checkbox.Group>
      </div>
    );
  };

  return (
    <Dropdown
      placement="topLeft"
      open={menuOpen}
      trigger={[ 'click' ]}
      onOpenChange={open => {
        hasSource && setMenuOpen(open);
      }}
      dropdownRender={renderMenu}
      overlayStyle={{ zIndex: 99 }}
      disabled={disabledShowSource}>
      <div
        className={classNames(
          styles.button,
          disabledShowSource && styles.disabledButton,
        )}
        style={isMobile ? buttonStyle.mobile : buttonStyle.pc}
      >
        Source
        <IconFont
          type="arrow-down"
          ref={iconRef}
          style={{
            fontSize: 18,
            transition: 'transform 0.4s',
            color: '#8E8E93',
            marginLeft: 7,
          }}
        />
      </div>
    </Dropdown>
  );
};

export default SourceSelect;
