import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { CompanyPlugin, companyMenuId } from './plugins/CompanyPlugin';
import styles from './index.module.css';
import { Typography } from 'antd';
import { Grid } from 'antd';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { CompanyNode } from './nodes/CompanyNode';
import { theme } from '../../theme';
import {
  COMMAND_PRIORITY_HIGH,
  EditorState,
  KEY_ENTER_COMMAND,
  LexicalEditor,
} from 'lexical';
import { useEffect, useRef } from 'react';
import React from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { PlaceholderNode } from './nodes/PlaceholderNode';
import classNames from 'classnames';
import { getStringFromEditorState } from 'utils/lexical';
import SourceSelect from 'components//SourceSelect';
import _ from 'lodash';
import ismobilejs from 'ismobilejs';
import { ChatContext } from 'components/Chat/useContext';
import IconFont from 'components/IconFont';
import { useSize } from 'ahooks';

const { useBreakpoint } = Grid;

const Placeholder = ({ openReference, isMobile }) => {
  const screens = useBreakpoint();
  return (
    <Typography.Text
      type="secondary"
      style={{
        fontStyle: 'italic',
        left: isMobile ? 10 : _.isEmpty(openReference) ? '16%' : 95,
        width: isMobile ? '100%' : '75%',
      }}
      className={styles.placeholder}
    >
      {screens.lg && !openReference ? (
        <span>
          Message the Librarian: Use '<span style={{ color: 'black' }}>@</span>'
          followed by company name
        </span>
      ) : (
        <span>
          Use '<span style={{ color: 'black' }}>@</span>' followed by company
          name
        </span>
      )}
    </Typography.Text>
  );
};

const EditorRef = React.forwardRef<LexicalEditor, any>((props, ref) => {
  const [ editor ] = useLexicalComposerContext();
  React.useImperativeHandle(ref, () => editor, [ editor ]);
  return <></>;
});

const EnterCommand = ({ onSubmit = () => {}, disableSent }) => {
  const [ editor ] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerCommand(
      KEY_ENTER_COMMAND,
      event => {
        if (!event) return false;
        const { shiftKey, code } = event;
        const typeheadMenu = document.getElementById(companyMenuId);

        if (code == 'Enter' && !shiftKey && !typeheadMenu && !disableSent) {
          event.preventDefault();

          onSubmit();

          return true;
        }
        return false;
      },
      COMMAND_PRIORITY_HIGH,
    );
  }, [ editor, onSubmit ]);

  return null;
};

export type PromptInputProps = {
  onSubmit: (
    editorState: EditorState | null,
    editor: LexicalEditor | null
  ) => void;
  className?: string;
  onChange?: (editorState: EditorState, editor: LexicalEditor) => void;
  disableNewTopic?: boolean;
};

const editorConfig = {
  namespace: 'prompt-input',
  onError(error) {
    throw error;
  },
  nodes: [
    CompanyNode,
    PlaceholderNode /* MetricNode, SectorNode, IndustryNode */,
  ],
  theme: {
    paragraph: styles.paragraph,
  },
};

const PromptInput = React.forwardRef<LexicalEditor, PromptInputProps>(
  (
    {
      onSubmit,
      className,
      disableNewTopic,
      investmentTid,
      openReference,
      ...props
    }: PromptInputProps,
    ref,
  ) => {
    const editorState = useRef<EditorState | null>(null);
    const editor = useRef<LexicalEditor | null>(null);
    const editorBoxRef = useRef(null);
    const [ disableSent, setDisableSent ] = React.useState(true);
    const screens = useBreakpoint();
    const isMobile = ismobilejs(window.navigator).any || !screens.lg;
    const { source, isInvestment } = React.useContext(ChatContext);
    const size = useSize(editorBoxRef);

    const onChange = (state: EditorState, edit: LexicalEditor) => {
      editorState.current = state;
      editor.current = edit;
      props.onChange?.(state, edit);

      const editorString = getStringFromEditorState(state);
      if (!editorString.match(/^\s*$/)) {
        setDisableSent(false);
      } else {
        setDisableSent(true);
      }
    };

    const disabled = disableSent || disableNewTopic || (source.length === 1 && !isInvestment);

    const submit = () => {
      if (disabled) return;
      onSubmit(editorState.current, editor.current);
    };

    const selectionStyle = {
      pc: {
        width: openReference ? 84 : '14%',
        borderRight: '1px solid #d1d1d6',
        borderRadius: '8px 0 0 8px',
      },
      mobile: {
        width: size?.width ? size.width : '100%',
        bottom: 40,
        border: '1px solid rgb(243,244,245)',
        borderRadius: '8px 8px 0 0',
        borderBottom: 'none',
      },
    };


    return (
      <LexicalComposer initialConfig={editorConfig}>
        <div className={classNames(className, styles.wrapper)}>
          <div
            ref={editorBoxRef as any}
            className={styles.editorBox}
            style={{ borderRadius: isMobile ? '0 0 8px 8px' : 8 }}
          >
            <div
              className={styles.selection}
              style={isMobile ? selectionStyle.mobile : selectionStyle.pc}
            >
              <SourceSelect width={size?.width} />
            </div>
            <PlainTextPlugin
              contentEditable={
                <ContentEditable
                  className={styles.editor}
                  style={{
                    paddingLeft: isMobile ? 10 : openReference ? 96 : '16%',
                  }}
                />
              }
              placeholder={<Placeholder openReference={openReference} isMobile={isMobile}/>}
              // @ts-ignore
              ErrorBoundary={undefined}
            />
            <EditorRef ref={ref} />
            <CompanyPlugin />
            {/* <MetricPlugin /> */}
            <OnChangePlugin onChange={onChange} />
            <EnterCommand onSubmit={submit} disableSent={disableSent} />

            <IconFont
              type="send-icon"
              style={{
                fontSize: 22,
                color:
                disabled
                  ? '#F7932966'
                  : theme.colors.orange,
                cursor:
                disabled ? 'not-allowed' : 'pointer',
              }}
              onClick={() => (disabled ? null : submit())}
              className={styles.icon}
            />
          </div>
        </div>
      </LexicalComposer>
    );
  },
);

export default PromptInput;
