import React, { useContext, useEffect, useMemo } from 'react';
import Chat from 'components/Chat';
import { Link, useParams } from 'react-router-dom';
import { ChatHistoryDetail } from 'request/apis/portageurTypes';
import { useAsync } from 'hooks/useAsync';
import { PortageurApi } from 'request/apis/portageur';
import { Message } from 'components/MessagesRenderer/types';
import Loading from 'components/Loading';
import { Typography } from 'antd';
import styles from './details.module.css';
import MarkdownRenderer from 'components/MarkdownRenderer';
import { parse } from 'query-string';
// import { LeftCircleFilled } from '@ant-design/icons';
import classNames from 'classnames';
import { SidebarContext } from 'components/Layout';
import IconFont from 'components/IconFont/index';
import { FRONTEND_MESG } from 'config/constant/index';

const portageurApi = new PortageurApi();

const ChartHistoryDetails = () => {
  const params = useParams();
  const query = parse(location.search);

  const [ hideNewTopic, setHideNewTopic ] = React.useState(true);

  const sidebar = useContext(SidebarContext);

  const detailsQuery = useAsync<ChatHistoryDetail>();

  useEffect(() => {
    if (params.sessionId) {
      detailsQuery.runAsync(
        (async () => {
          const res = await portageurApi.getChatHistoryDetail({
            sessionId: params.sessionId!,
            types: +query.types,
          });

          return res.data;
        })(),
      );
    }
  }, [ params.sessionId ]);

  const { messages, currentRequestId, investmentTid } = useMemo(() => {
    let messages: Message[] = [];
    let currentRequestId;
    let investmentTid = '';

    const investmentQuestion = {
      requestId: 0,
      message: FRONTEND_MESG.RecipeQuestion.message,
      html: (
        <MarkdownRenderer markdown={FRONTEND_MESG.RecipeQuestion.message} />
      ),
      sender: 'bot',
      status: 'question',
      references: [],
      pdf: [],
    };

    if (detailsQuery.data) {
      messages = detailsQuery.data.list.map(({ request_id, content, type }) => {
        const text =
          typeof content === 'string' ? content : content?.answer || '';
        const references =
          typeof content === 'string' ? [] : content?.text_elements || [];
        const pdf = typeof content === 'string' ? [] : content?.reference || [];

        return {
          requestId: request_id,
          message: text,
          html: <MarkdownRenderer markdown={text} />,
          sender: type === 'prompt' ? 'user' : 'bot',
          status: 'answer',
          references,
          pdf,
        };
      });
      investmentTid = detailsQuery.data.list[0].tid;
      currentRequestId =
        detailsQuery.data.list[detailsQuery.data.list.length - 1].request_id;
    }

    if (query.service === 'Investment') {
      messages.unshift(investmentQuestion);
    }

    return { messages, currentRequestId, investmentTid };
  }, [ detailsQuery.data ]);

  const onChange = () => {
    setHideNewTopic(false);
  };

  return (
    <>
      {hideNewTopic && (
        <Link
          className={classNames(
            styles.link,
            sidebar.isOpen && styles.linkSideOpen,
          )}
          to={`/chat-history?page=${query.page}`}>
          <IconFont type="left-circle" style={{ fontSize: 20 }} />
          {location.pathname.includes('/chat-history')
            ? 'Back to History'
            : 'Back to the Chats'}
        </Link>
      )}

      {detailsQuery.status === 'pending' && <Loading width={100} />}
      {detailsQuery.status === 'rejected' && (
        <Typography.Text type="danger" className={styles.error}>
          Something went wrong
        </Typography.Text>
      )}

      {detailsQuery.status === 'resolved' && (
        <Chat
          hideNewTopicButton={hideNewTopic}
          initialMessages={messages}
          initialRequestId={currentRequestId}
          initialSessionId={params.sessionId}
          initialTid={investmentTid}
          onInputChange={onChange}
          insertQrank={detailsQuery.data?.list[0]?.qrank}
        />
      )}
    </>
  );
};

export default ChartHistoryDetails;
